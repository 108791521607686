import React from 'react';
import { navigate } from 'gatsby';

import { ROUTES } from '_constants';
import { useUserAccess } from 'state/hooks';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useUserAccess();

  if (!isAuthenticated) {
    navigate(ROUTES.ROOT, { replace: true });
    return null;
  }

  return <Component {...rest} />;
};
