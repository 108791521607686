import React from 'react';
import { Router } from '@reach/router';

import { ROUTES } from '_constants';

import { PrivateRoute } from 'components/PrivateRoute';
import { UserAccount } from 'components/User';
import { ForceRedirect } from 'components/ForceRedirect';

export default function App() {
  return (
    <Router basepath={ROUTES.BASE_APP}>
      {/* Temporary redirect to circle from broken redirect link */}
      <ForceRedirect
        path='devrev-account/settings'
        to={ROUTES.DEV_REV_CIRCLE}
      />
      
      <PrivateRoute
        path={ROUTES.USER_DEVREV_ACCOUNT()}
        component={UserAccount}
      />
    </Router>
  );
}
